import { useCallback, useMemo } from "react";
import Campaign from "../../../models/Campaign";
import {
  getCampaignPeriodType,
  getCampaignState,
  getCampaignTargetType,
} from "../../../tools/getCampaign";
import { ExtendedCampaign } from "../models/ExtendedCampaign";

export const useCampaignController = () => {
  const transformRead = useCallback((c: Campaign) => {
    return {
      ...c,
      periodType: getCampaignPeriodType(c),
      state: getCampaignState(c, new Date()),
      targetType: getCampaignTargetType(c),
    } as ExtendedCampaign;
  }, []);

  const transformBeforeSubmit = useCallback((c: ExtendedCampaign) => {
    delete c.state;
    delete c.periodType;
    delete c.targetType;
    return c;
  }, []);

  const defaultResource = useMemo(
    () =>
      ({
        periodType: "permanent",
        targetType: "all",
      } as ExtendedCampaign),
    []
  );

  return { transformRead, transformBeforeSubmit, defaultResource };
};
