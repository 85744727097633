import Campaign, {
  CampaignPeriodType,
  CampaignTargetType,
} from "../models/Campaign";
import { CampaignState } from "../models/CampaignState";

export const getCampaignTargetType = <TCampaign extends Campaign>(
  c: TCampaign
): CampaignTargetType =>
  c.targetStoreId != null
    ? "store"
    : c.targetAnimationGroupId != null
    ? "animation-group"
    : "all";

export const getCampaignPeriodType = <TCampaign extends Campaign>(
  c: TCampaign
): CampaignPeriodType => {
  return !c.startDate && !c.endDate ? "permanent" : "planified";
};

export const getCampaignState = <TCampaign extends Campaign>(
  c: TCampaign,
  now: Date
): CampaignState =>
  c.endDate == null || now <= new Date(c.endDate)
    ? CampaignState.Active
    : CampaignState.Passed;
